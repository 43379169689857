import { t } from '@zupr/i18n'
import debounce from 'lodash.debounce'
import { ForwardedRef, forwardRef, useCallback, useMemo, useState } from 'react'
import useKeypress from 'react-use-keypress'
import ScanBarcodeButton from './scan-barcode-button'

import Clear from '../../../svg/cross.svg'
import SearchIcon from '../../../svg/search.svg'

import '../../../scss/react/components/searchbox.scss'

interface SearchboxProps {
    value: string
    placeholder?: string
    onEnter?: (value: string) => void
    onChange?: (value: string) => void
    onFocus?: () => void
    onClear?: () => void
    debounceDelay?: number
    scanBarcode?: boolean
}

const Searchbox = forwardRef(
    (
        {
            value,
            placeholder = 'Search...',
            onFocus,
            onEnter,
            onChange,
            onClear,
            debounceDelay,
            scanBarcode = true,
        }: SearchboxProps,
        ref: ForwardedRef<HTMLInputElement>
    ) => {
        const [focus, setFocus] = useState(false)

        const handleFocus = useCallback(() => {
            setFocus(true)
            onFocus && onFocus()
        }, [onFocus])

        const handleBlur = useCallback(() => {
            setFocus(false)
        }, [])

        const handleKeyEnter = useCallback(() => {
            if (!focus) return null
            if (onEnter) onEnter(value)
        }, [focus, onEnter, value])

        useKeypress('Enter', handleKeyEnter)

        const debouncedChange = useMemo(() => {
            if (!debounceDelay) return onChange
            return debounce(onChange, debounceDelay)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [debounceDelay, onChange, value])

        const handleChange = useCallback(
            ({ target: { value } }) => {
                debouncedChange(value)
            },
            [debouncedChange]
        )

        const handleClear = useCallback(() => {
            if (onClear) onClear()
            if (!onClear) onChange(null)
        }, [onClear, onChange])

        const handleScan = useCallback(
            (barcode: string) => {
                handleFocus()
                onChange(barcode)
                if (onEnter) onEnter(barcode)
            },
            [handleFocus, onChange, onEnter]
        )

        return (
            <div className="searchbox">
                <input
                    type="text"
                    ref={ref}
                    placeholder={t(placeholder)}
                    value={value || ''}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                <SearchIcon className="icon-search" />
                {value && value !== '' && (
                    <button
                        type="button"
                        className="btn btn-micro btn-light"
                        onClick={handleClear}
                    >
                        <Clear />
                    </button>
                )}
                {!value && scanBarcode && (
                    <ScanBarcodeButton onScan={handleScan} />
                )}
            </div>
        )
    }
)

export default Searchbox
