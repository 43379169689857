import { t } from '@zupr/i18n'
import dynamic from 'next/dynamic'
import { useCallback, useState } from 'react'

import BarcodeIcon from '../../../svg/scan-barcode.svg'
import ModalDialog, { ModalDialogFrame } from './dialog'

const ScanBarcode = dynamic(() => import('./barcode-scanner'), {
    ssr: false,
})

interface Props {
    onScan: (barcode: string) => void
}

const ScanBarcodeButton = ({ onScan }: Props) => {
    const [scanBarcode, setScanBarcode] = useState(false)

    const handleScan = useCallback(
        (barcode: string) => {
            setScanBarcode(false)
            onScan(barcode)
        },
        [onScan]
    )

    return (
        <>
            <button
                type="button"
                className="btn btn-small btn-transparent"
                onClick={() => setScanBarcode(!scanBarcode)}
            >
                <BarcodeIcon />
            </button>
            {scanBarcode && (
                <ModalDialog
                    isOpen={scanBarcode}
                    onClose={() => setScanBarcode(false)}
                    position="center"
                    className="scan-barcode-modal"
                >
                    <ModalDialogFrame
                        title={t('Scan product')}
                        onClose={() => setScanBarcode(false)}
                    >
                        <ScanBarcode
                            onScan={handleScan}
                            paused={!scanBarcode}
                        />
                    </ModalDialogFrame>
                </ModalDialog>
            )}
        </>
    )
}

export default ScanBarcodeButton
